import { css } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

const style = {
  image: css`
    margin: 1rem 0;
    width: 240px;
  `,

  font: css`
    font-family: var(--text-normal);
    font-size: var(--fs-xs);
  `,
}

const ServerErrorPage = () => {
  return (
    <div className="px-3 py-5 text-center">
      <img css={style.image} src="/images/yuTaM.png" alt="server-error" />
      <div className="fs-3" css={style.font}>
        <FormattedMessage
          id="ServerErrorPage.titleText"
          defaultMessage="Server Error"
        />
      </div>
      <div className="mt-3" css={style.font}>
        <FormattedMessage
          id="ServerErrorPage.descriptionText"
          defaultMessage="An error occurred in the server and your page could not be served. Please try again later."
        />
      </div>
    </div>
  )
}

export default ServerErrorPage
